<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
// .link-table
//   .link-setting
//     opacity: 0
//   &:hover
//     .link-setting
//       opacity: 1
.table-preview
  font-size: 11px
  thead
    tr
      th
        // font-weight: normal
        color: #999
.container-search
  height: 53px
  position: fixed
  top: 0
  left: 300px
  width: 100%
.container-search-pad
  padding-top: 53px
.link
  padding: 1rem 0rem
  font-size: 12px
  color: #343a40
  &.router-link-active
    strong
      color: #343a40
      border-bottom: solid 1px #343a40
  &:hover
    text-decoration: none
  strong
    padding-bottom: 0.25rem
    // padding: 1rem 1rem
    // border-bottom: solid 1px #ccc
    color: #777
</style>
<template lang="pug">
div
  router-view
  //- .container-search-pad
  //- div.container-search.bg-white.shadow-sm.pl-2
    form.form-inline
      input.form-control.bg-light.mr-2(type='text' placeholder='고객검색')
      input.form-control.bg-light.mr-2(type='text' placeholder='내용검색')
      button.btn.btn-default.text-primary(type='submit') 검색
  //- .bg-white.shadow-sm.mb-2
    .p-3
      .mb-4
        router-link.link.mr-3(:to='`/property/${property_id}/activity`')
          strong 목록조회
        router-link.link.mr-3(:to='`/property/${property_id}/customer/dsfsd`')
          strong 변경내역
        //- a.link.mr-3(href='#') 변경내역

      h5 전체 목록조회


      a.mr-2(href='#' @click='toggle_order(`updated_at`)'): small 최근순
        b-icon-caret-down-fill(v-show='order_by == `updated_at` && order==`ASC`')
        b-icon-caret-up-fill(v-show='order_by == `updated_at` && order==`DESC`')
      a.mr-2(href='#' @click='toggle_order(`created_at`)'): small 생성일순
        b-icon-caret-down-fill(v-show='order_by == `created_at` && order==`ASC`')
        b-icon-caret-up-fill(v-show='order_by == `created_at` && order==`DESC`')
      a.mr-2(href='#' @click='toggle_order(`name`)'): small 가나다순
        b-icon-caret-down-fill(v-show='order_by == `name` && order==`ASC`')
        b-icon-caret-up-fill(v-show='order_by == `name` && order==`DESC`')
      .bg-white.shadow-sm.mb-2.border(v-for='document in documents')
        .p-3
          router-link.d-block.text-left(:to='`/property/${property_id}/customer/${document.id}`')
            strong {{document.name}}
        table.table.table-preview
          thead
            tr
              th.border-0(v-for='col in document.config.cols' v-if='col.visible_at_table == `Y`') {{col.label}}
        //- button.btn(type='button') 내용보기


  //- .bg-light(style='width: 2000px; height: 100vh')
    p sdfsdf
  //- div.container-left
    .container-left-top.rounded-left.shadow-sm.ml-1
      .row.justify-content-center.align-items-center(style='')
        .col
          .p-4.container-customer
            h5.title
              span {{$store.state.property.name}}
              span.text-white .
            .row.pt-3.async(:class='{done: done}')
              template(v-for='document in documents')
                router-link.btn.btn-sm.btn-block.text-left.link-table(
                  :to='`/property/${property_id}/customer/${document.id}`'
                  :class='[(document.id == document_id ? `btn-light text-primary` : `btn-default`) ]'
                ) {{document.name}}
                  router-link.float-right.text-secondary(v-if='document.id == document_id' :to='`/property/${property_id}/customer/${document.id}/setting`' @click.prevent='') 설정

              .col.text-center.mt-3(style='opacity: .2' v-show='documents.length === 0')
                strong 목록이 없습니다.


            .mb-4
            a.btn.btn-default.text-primary.btn-block(type='button' @click='add_document()' :disabled='adding') 고객목록 추가
              //- b-spinner.ml-4.async(label='가져오는중' small variant='secondary' :class='{done: adding}')
            //- h5 관련 정보
            //- p 관련 정보가 없습니다.

    //- .container-left-bottom.shadow.p-2
      //- a.link.p-3.d-block(href='#') 새 목록 만들기
      //- a.btn.btn-default.text-primary.btn-block 고객목록 추가

  //- div.container-right.bg-white.shadow-sm
    router-view


</template>

<script>

// import jquery from "jquery";

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    // documents() {
    //   return this.$store.state.documents
    // },
  },
  data() {
    return {
      done: false,
      adding: false,
      documents: [],
      current_document: {},

      order_by: 'created_at',
      order: 'ASC',
    }
  },
  async mounted() {
    // this.loaded = true
    this.load()
    // this.done = true
  },
  methods: {
    async load() {
      try {
        await this.$store.dispatch('documents', this.property_id)

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    toggle_order(order_by) {
      const next_value = {'ASC': 'DESC', 'DESC': 'ASC'}

      if (order_by != this.order_by) {
        this.order_by = order_by
      }

      this.order = next_value[this.order]
      this.load()
    },
    async add_document() {
      try {
        this.adding = true
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객목록 추가 실패')

        await this.$store.dispatch('documents', this.property_id)

        setTimeout(() => {
          this.adding = false

          this.$router.push({
            path: `/property/${this.property_id}/customer/${r.data.document_id}/setting`
          })

        }, 300);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    open_menu(event, document) {
      // todo: contextmenu

      this.current_document = Object.assign({}, document)

      this.$modal.show('contextmenu', {
        params: {
          clientX: event.clientX,
          clientY: event.clientY,
        }
      })

      setTimeout(() => {
        const $input = this.$el.querySelector('#customer-contextmenu input[autofocus]')
        if ($input) $input.focus()
      }, 300);
    }
  },
}
</script>
